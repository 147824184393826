import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursor',
  templateUrl: './cursor.component.html',
  styleUrls: ['./cursor.component.scss']
})
export class CursorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // const cursor = curDot({
    //   diameter: 30,
    //   easing: 4
    // });

    // cursor.over(".title", {
    //   scale: 5,
    //   background: "#fff"
    // });

    // cursor.over(".react1", {
    //   borderColor: "rgba(255,255,255,.38)"
    // });

    // cursor.over(".welcome-text-container", {
    //   scale: 5,
    //   background: "#fff"
    // });

    // cursor.over(".react3", {
    //   scale: 4,
    //   background: "#faa2c1",
    //   borderColor: "transparent"
    // });
  }


}
